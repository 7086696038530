<template>
    <a-tabs class="myTabs" v-model:activeKey="activeKey" @change="tabChange">
        <a-tab-pane v-for="item in tabsList" :key="item.code" :tab="item.name"></a-tab-pane>     
        <template #leftExtra>
            <a-dropdown :trigger="['click']" class="dropdownStyle" v-model:visible="visible">
                <div>
                    <span>{{ projectInfo.name }}</span>
                    <DownOutlined />
                </div>
                <template #overlay>
                    <div class="treeStyle">
                        <a-tree :tree-data="treeData" v-model:checkedKeys="projectIds"
                            :fieldNames="{ children: 'children', title: 'name', key: 'projectBizId' }"
                            @check="handleSelectTree" checkable>
                        </a-tree>
                    </div>
                </template>
            </a-dropdown>
        </template>
    </a-tabs>
</template>
<script setup>

import { ref, defineEmits, onMounted } from 'vue'
import { apiUserProjectTree } from "@/api/common.js";


const visible = ref(false)
const tabsList = [
    {
        name: '当前告警',
        code: 'current'
    },
    {
        name: '历史告警',
        code: 'history'
    }
]
const activeKey = ref('current')

const companyInfo = ref({})
const treeData = ref([])
const projectInfo = ref({})
const projectIds = ref(null)
const initProjectTree = async () => {

    let res = await apiUserProjectTree()
    const { children, name, bizNodeId } = res.result || {}
    treeData.value = children || []
    companyInfo.value = { name, id: bizNodeId }
    projectInfo.value = findFirstProject(res.result)
    projectIds.value = [projectInfo.value.projectBizId]
    emits('check', projectIds.value)
}
const findFirstProject = (source) => {
    let findFlag = false
    let result;
    function findLoop(source) {
        if (findFlag) return;

        if (source.projectBizId) {
            findFlag = true
            result = source
            console.log(1234444, result)
        }
        if (source.children) {
            source.children.forEach(item => {
                if (item.projectBizId && !findFlag) {
                    result = item
                    findFlag = true
                    console.log(1235555, result)
                } else if (!findFlag) {
                    findLoop(item)
                }
            });
        }
    }
    findLoop(source)
    console.log('end', result)
    return result
}

const emits = defineEmits(['check', 'tab'])
const handleSelectTree = (...arg) => {

    const { checkedNodes } = arg[1]
    const ids = checkedNodes && checkedNodes.map(item => item.projectBizId)
    console.log(2323, ids)
    if (ids.length > 0) {
        projectIds.value = ids
    } else {
        projectIds.value = null
    }
    emits('check', ids)
}
const tabChange = (val) => {
    emits('tab', val)
}

onMounted(async () => {
    await initProjectTree()
})
</script>
<style lang='less' scoped>
.dropdownStyle {
    z-index: 10;
    position: relative;
    margin-right: 20px;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    color: #000;

    span {
        margin-right: 5px;
    }

    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 4px;
        background: #EBEBEB;
        left: 0;
        bottom: -10px;
    }
}

.treeStyle {
    position: relative;
    padding: 18px 12px;
    box-shadow: 0 0 2px 2px #D8D8D8;
    background: #fff;
}

.myTabs {
    :deep(.ant-tabs-nav) {
        min-height: 46px !important;
    }
}
</style>