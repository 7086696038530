<template>
    <div class="common-wrapper" style="padding-top: 0;">
        <top-tabs @tab="handleTabChange" @check="handleTreeCheck" />
        <div class="content-header">
            <div>
                <a-range-picker valueFormat="YYYY-MM-DD HH:mm:ss" v-if="currentAlarmType === 'history'"
                    class="datepicker" v-model:value="searchTimes" @change="onChangeTimes" show-time allowClear />
                <a-radio-group v-model:value="filterAlarmType" button-style="solid" @change="alarmTypeChange">
                    <template v-for="item in alarmTypeList" :key="item.alarmType">
                        <a-radio-button :value="item.alarmType">{{ item.alarmTypeName }}({{ item.number
                            }})</a-radio-button>
                    </template>
                </a-radio-group>
                <categorySearch style="margin-bottom: 0;" :source="filterSource" class="searchBlock"
                    @change="handleSearch" />
            </div>
            <a-button v-if="currentAlarmType === 'history'" @click="handleExport">导出</a-button>
        </div>
        <basic-table ref="tableRef" v-bind="tableConfig" @page-change="pageChange" @pagesize-change="pageSizeChange"
            :scroll="{ x: currentTabCategoryId === 'PC0001' ? '120%' : '100%', y: tableHeight }">
            <template #slotAlarmLevel="{ row }">
                <a-tag color="blue" v-if="row.alarmLevel == '00'">{{ row.alarmLevelName }}</a-tag>
                <a-tag color="green" v-else-if="row.alarmLevel == '01'">{{ row.alarmLevelName }}</a-tag>
                <a-tag color="orange" v-else-if="row.alarmLevel == '02'">{{ row.alarmLevelName }}</a-tag>
                <a-tag color="red" v-else>{{ row.alarmLevelName }}</a-tag>
            </template>
        </basic-table>
        <a-modal v-model:visible="modalVisible" title="确认告警" @ok="handleOk">
            <div>
                <a-textarea :disabled="currentActionRow.isConfirm ? true : false" placeholder="请填写确认内容"
                    v-model:value="confirmMessage"></a-textarea>
            </div>
        </a-modal>
    </div>
</template>

<script setup>
// defineOptions({
//     name: 'EquipmentMonitor'
// })
import categorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from '@/components/basic/table'
import { reactive, ref, onMounted, onBeforeUnmount } from 'vue'
import TopTabs from './components/topTabs.vue'
import { apiMonitorAlarm } from "@/api/centerMonitor/deviceAlarm.js";
import { apiDictListByCode } from "@/api/common.js";
import dayjs from 'dayjs';
import _ from "lodash";
import { downLoadFile } from "@/utils/excel";
import { message } from "ant-design-vue";

const searchForm = ref({})
const filterSource = ref([

    {
        label: "告警等级",
        type: "select",
        bindKey: "alarmLevel",
        options: [],
    },
    {
        label: '告警码',
        type: 'input',
        bindKey: 'alarmCode',
        // bindValue: 'ee',
        placeholder: '请输入设备名称'
    },
    {
        label: "告警状态",
        type: "select",
        bindKey: "alarmStatus",
        options: [],
    }
])

const handleSearch = (form) => {
    searchForm.value = form
    pageChange(1)
}

const alarmTypeList = ref([])
const initCurrentTypeNum = () => {
    console.log(112, currentAlarmType.value)
    let apiMd;
    if (currentAlarmType.value === 'current') {
        apiMd = apiMonitorAlarm.currentTypeNum
    }
    if (currentAlarmType.value === 'history') {
        apiMd = apiMonitorAlarm.historyTypeNum
    }
    apiMd({ projectBizIds: currentProjectIds.value.join(',') }).then(res => {
        console.log(333, res)
        let total = 0;
        alarmTypeList.value = res.result || []
        res.result.forEach(item => {
            total += item.number
        })
        alarmTypeList.value.unshift({
            alarmTypeName: '全部',
            alarmType: '',
            number: total,
        })
        filterAlarmType.value = '';
        initTableData()
    })
}

const tableConfig = reactive({
    pagination: true, //分页
    isPageBottom: true, //分页在底部
    remote: false,  //!!!!!!!!! 自动加载
    // remoteApi: apiDeviceMonitorPage,//!!!!!!!!!!自动加载
    immediate: false, //立即加载数据
    rowKey: "id",
    queryParams: {}, //过滤参数
    columns: [
        {
            title: "告警ID",
            type: "text",
            key: "alarmBizId",
            width: 100
        },
        {
            title: "站点",
            type: "text",
            key: "projectName",
        },
        {
            title: "告警类型",
            type: "text",
            key: "alarmTypeName",
        },
        {
            title: "设备工艺",
            type: "text",
            key: "objName",
        },
        {
            title: "告警码",
            type: "text",
            key: "alarmCode",
        },
        {
            title: "告警内容",
            type: "text",
            key: "creatorName",
        },
        {
            title: "告警等级",
            type: "customize",
            slotName: "slotAlarmLevel",
        },
        {
            title: "告警时间",
            type: "text",
            key: "eventTime",
            formatter(val) {
                return dayjs(val).format('YYYY-MM-DD HH:mm:ss');
            },
        },
    ],
    action: {
        title: "确认",
        fixed: "right",
        items: [
            {
                icon: "CheckCircleFilled",
                iconTip: '确认',
                type: "icon",
                onClick: (row) => {
                    handleSure(row);
                },
                visibleFn: (row) => {
                    return !row.isConfirm
                }
            },
            {
                icon: "EyeFilled",
                iconTip: '查看',
                type: "icon",
                onClick: (row) => {
                    handleSure(row);
                },
                visibleFn: (row) => {
                    return row.isConfirm
                }
            },
        ],
    },
})


const confirmMessage = ref('')
const modalVisible = ref(false)
const handleOk = () => {
    if (currentActionRow.value.isConfirm) {
        modalVisible.value = false;
        return;
    }
    apiMonitorAlarm.actionConfirm({
        eventId: currentActionRow.value.eventId,
        remark: confirmMessage.value
    }).then(() => {
        initTableData()
        modalVisible.value = false;
    })
}
const currentActionRow = ref(null);
const handleSure = (row) => {
    currentActionRow.value = row
    modalVisible.value = true;
    confirmMessage.value = row.confirmRemark
}

const tableHeight = ref(500)
const changeTableHeight = () => {
    tableHeight.value = document.body.clientHeight - 320
}

const searchTimes = ref(null)
const onChangeTimes = () => {
    initTableData()
};

const allFilterSource = ref([])
const initSearchInfo = async () => {
    const _list = _.cloneDeep(filterSource.value);
    let res = await apiDictListByCode('ALARM_LEVEL');
    _list[0].options = res.result;
    filterSource.value = _list;
    let res2 = await apiDictListByCode('ALARM_STATUS');
    _list[2].options = res2.result;
    allFilterSource.value = _list
    filterSource.value = _list.slice(0, 2);
}

const times = ref(null)
onMounted(() => {
    changeTableHeight()
    window.addEventListener('resize', changeTableHeight)
    tableRef.value.paginationProps.pageSize = 20

    clearInterval(times.value)
    times.value = setInterval(() => {
    }, 30000)
    initSearchInfo()


    // initCurrentTypeNum()
})
onBeforeUnmount(() => {
    window.removeEventListener('resize', changeTableHeight)
    clearInterval(times.value)
})

const alarmTypeChange = () => {
    //console.log(22, val)
    initTableData()
}

const handleExport = () => {
    let param = {
        pageNo: tableRef.value.paginationProps.current,
        pageSize: tableRef.value.paginationProps.pageSize,
        projectBizIds: currentProjectIds.value.join(','),
        alarmType: filterAlarmType.value,
        ...searchForm.value
    }
    if (currentAlarmType.value === 'history' && searchTimes.value) {
        param.startTime = searchTimes.value[0]
        param.endTime = searchTimes.value[1]
    }
    apiMonitorAlarm.historyExport(param).then((res) => {
        const { filename, blob } = res;
        downLoadFile(blob, decodeURI(filename));
        message.success("导出成功");
    })
}

//自动加载数据
const tableRef = ref();
const initTableData = async () => {
    let api;
    if (currentAlarmType.value === 'current') {
        api = apiMonitorAlarm.currentList
    }
    if (currentAlarmType.value === 'history') {
        api = apiMonitorAlarm.historyList
    }
    console.log(333444)
    try {
        let param = {
            pageNo: tableRef.value.paginationProps.current,
            pageSize: tableRef.value.paginationProps.pageSize,
            projectBizIds: currentProjectIds.value.join(','),
            alarmType: filterAlarmType.value,
            ...searchForm.value
        }
        if (currentAlarmType.value === 'history' && searchTimes.value) {
            param.startTime = searchTimes.value[0]
            param.endTime = searchTimes.value[1]
        }
        let result = await api(param)
        if (result.result) {
            tableRef.value.dataSourceRef = filterData(result.result.records)
            tableRef.value.paginationProps.total = result.result.total
        } else {
            tableRef.value.dataSourceRef = [];
        }
    } catch (error) {
        clearInterval(times.value)
    }
};
const filterData = (source = []) => {

    const copySource = _.cloneDeep(source);

    copySource.forEach(item => {
        let innerData = item.current && item.current.current;
        for (let key in innerData) {
            item[key] = innerData[key]
        }
    })
    return copySource
}
//手动加载数据---需要分页自己实现
const pageChange = (pageIndex) => {
    tableRef.value.paginationProps.current = pageIndex
    initTableData()
}
// const pageSizeChange = (size)=>{
//  // tableRef.value.paginationProps.pageSize = size
// }


const currentAlarmType = ref('current')
const handleTabChange = (code) => {
    currentAlarmType.value = code
    initCurrentTypeNum()
    if (currentAlarmType.value === 'current') {
        filterSource.value = allFilterSource.value.slice(0, 2);
    } else {
        filterSource.value = allFilterSource.value.slice(0, 3);
    }


}

const filterAlarmType = ref('all')


const currentProjectIds = ref(null)
const handleTreeCheck = (info) => {
    console.log(info);
    if (Array.isArray(info) && info.length > 0) {
        currentProjectIds.value = info.filter(e => e)
    } else {
        currentProjectIds.value = null
    }
    initCurrentTypeNum()
}





</script>
<style lang='less' scoped>
.content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    &>div {
        display: flex;
    }

    .ant-radio-group {
        margin-right: 10px;
    }

    .datepicker {
        width: 340px;
        margin-right: 10px;
    }

    // justify-content: space-between;
}
</style>